/* src/components/Footer.css */

.site-footer {
  background-color: #e0f8cce5; /* Soft background color */
  padding: 20px;
  border-top: 1px solid #e7e7e7;
  border-radius: 10px; /* Rounded corners for a modern look */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow at the top */
  margin-top: 40px; /* Space above the footer */
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Main Footer Sections */
.footer-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
}

/* Main Pages */
.footer-section.main-pages {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  font-family: 'Roboto', sans-serif; /* Different font for main pages */
}

.footer-section.main-pages h4 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
}

.footer-section.main-pages ul.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section.main-pages ul.footer-links li {
  margin-bottom: 8px;
}

.footer-section.main-pages ul.footer-links a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px; /* Smaller font size for links */
}

.footer-section.main-pages ul.footer-links a:hover {
  text-decoration: underline;
}

/* Policies */
.footer-section.policies {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  font-family: 'Roboto', sans-serif; /* Different font for policies */
}

.footer-section.policies h4 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
}

.footer-section.policies ul.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section.policies ul.footer-links li {
  margin-bottom: 8px;
}

.footer-section.policies ul.footer-links a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px; /* Smaller font size for links */
}

.footer-section.policies ul.footer-links a:hover {
  text-decoration: underline;
}

/* Contact */
.footer-section.contact {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  font-family: 'Roboto', sans-serif;
}

.footer-section.contact h4 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
}

.footer-section.contact ul.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section.contact ul.footer-links li {
  margin-bottom: 8px;
}

.footer-section.contact ul.footer-links a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.footer-section.contact ul.footer-links a:hover {
  text-decoration: underline;
}

/* Recent Blog Posts */
.footer-recent-posts {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  text-align: left;
  font-family: 'Roboto', sans-serif;
}

.footer-recent-posts h4 {
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
}

.footer-recent-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-recent-post {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.footer-recent-image {
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height */
  border-radius: 5px;
  object-fit: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.footer-recent-text {
  display: flex;
  flex-direction: column;
}

.footer-recent-text a {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.footer-recent-text a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.footer-post-date {
  font-size: 12px;
  color: #6c757d;
}

/* Company Information */
.footer-company-info {
  text-align: center;
  margin-top: 20px;
  font-family: 'Pacifico', cursive; /* Custom font for company name */
}

.footer-company-info h4 {
  margin-bottom: 10px;
  color: #333;
  font-size: 20px;
}

.footer-company-info p {
  color: #6c757d;
  font-size: 14px;
}

/* Error Message */
.footer-error {
  color: #e74c3c;
  font-size: 14px;
}

/* Skeleton Loaders */
.footer-recent-skeleton {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.skeleton-post {
  display: flex;
  gap: 10px;
}

.skeleton-image {
  width: 40px; /* Match the .footer-recent-image width */
  height: 40px; /* Match the .footer-recent-image height */
  background-color: #e0e0e0;
  border-radius: 5px;
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(
    to right,
    #e0e0e0 0%,
    #f0f0f0 20%,
    #e0e0e0 40%,
    #e0e0e0 100%
  );
  background-size: 800px 104px;
}

.skeleton-text {
  flex: 1;
  height: 14px; /* Approximate height for text */
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(
    to right,
    #e0e0e0 0%,
    #f0f0f0 20%,
    #e0e0e0 40%,
    #e0e0e0 100%
  );
  background-size: 800px 104px;
}

/* Shimmer Animation */
@keyframes shimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-main {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    max-width: 300px;
    text-align: center;
  }

  .footer-section.main-pages,
  .footer-section.policies,
  .footer-section.contact,
  .footer-recent-posts {
    margin: 10px 0;
  }

  .footer-section.main-pages h4,
  .footer-section.policies h4,
  .footer-section.contact h4,
  .footer-recent-posts h4 {
    font-size: 18px;
  }

  .footer-section.main-pages ul.footer-links a,
  .footer-section.policies ul.footer-links a,
  .footer-section.contact ul.footer-links a,
  .footer-recent-text a {
    font-size: 16px;
  }

  .footer-section.main-pages ul.footer-links a,
  .footer-section.policies ul.footer-links a,
  .footer-section.contact ul.footer-links a {
    font-size: 14px;
  }

  /* Recent Blog Posts */
  .footer-recent-post {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-recent-image {
    width: 60px;
    height: 60px;
  }

  .footer-recent-text a {
    font-size: 16px;
  }

  .footer-post-date {
    font-size: 14px;
  }

  /* Skeleton Loaders for Responsive */
  .skeleton-image {
    width: 60px; /* Match the .footer-recent-image width in responsive */
    height: 60px; /* Match the .footer-recent-image height in responsive */
  }

  .skeleton-text {
    height: 16px; /* Adjust height for responsive */
  }

  /* Company Information */
  .footer-company-info h4 {
    font-size: 18px;
  }

  .footer-company-info p {
    font-size: 14px;
  }
}
