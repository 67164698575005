body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap');

body {
  font-family: 'Shadows Into Light Two', cursive;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-shadow: 0.5px 0.5px 0 #000000; /* Subtle shadow to enhance readability */
  font-size: 1.2rem; /* Slightly larger text */
  letter-spacing: 0.5px; /* Add spacing between letters */
  font-weight: 450; /* Bolder effect if supported */
}


