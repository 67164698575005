/* src/components/PolicyPage.css */

.policy-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #fcd4c7ee;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.policy-page h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2em;
}

.policy-page h2 {
  margin-top: 20px;
  font-size: 1.5em;
}

.policy-page h3 {
  margin-top: 15px;
  font-size: 1.2em;
}

.policy-page p {
  margin-bottom: 15px;
}

.policy-page ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
}

.policy-page ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 15px;
}

.policy-page a {
  color: #007bff;
  text-decoration: none;
}

.policy-page a:hover {
  text-decoration: underline;
}

.policy-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px 0;
}

.policy-content blockquote {
  border-left: 4px solid #ccc;
  padding-left: 16px;
  color: #555;
  margin: 20px 0;
}

.policy-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
}

.policy-content th,
.policy-content td {
  border: 1px solid #ddd;
  padding: 8px;
}

.policy-content th {
  background-color: #f2f2f2;
  text-align: left;
}

.policy-content code {
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
}

.policy-content pre {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}
