/* Background Layer */
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('/public/images/Sunflower_background.webp');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: -1; /* Places it behind everything */
}

/* App Styles */
.App {
  position: relative;
  z-index: 1; /* Ensure it sits above the background */
  min-height: 100vh; /* Ensure it covers the viewport height */
  padding: 20px;
}
@import url('https://fonts.googleapis.com/css2?family=Mogra&display=swap');
/* Responsive Adjustments */
@media (max-width: 768px) {
  .App {
    padding: 0; /* Ensure no padding on mobile */
  }

  .hero {
    padding: 30px 5px; /* Adjust padding for mobile */
  }

  .hero-container {
    padding: 15px; /* Compact padding for smaller screens */
  }
  .main-content {
    padding-top: 70px; /* Adjust as needed for mobile view */
  }
}
.main-content {
  padding-top: 80px; /* Adjust based on your navbar's actual height */
  /* Other styles... */
}
